import "../styles/custom.scss";

import { Button, Col, Row } from "react-bootstrap";

import CheckmarkSvg from "../components/CheckmarkSvg";
import RLink from "../components/RLink";
import React from "react";
import { SafeExternalLink } from "../components/links";
import Seo from "../components/Seo";
import WavesLayout from "../components/WavesLayout";
import pricingTiers from "../modules/pricingTiers";
import staticData from "../components/staticData";

export default function Pricing() {
  return (
    <WavesLayout>
      <Seo title="Pricing" />
      <div className="d-flex justify-content-center">
        <Row className="mb-5 no-gutters" style={{ maxWidth: 1600 }}>
          <Col md={12} lg={6} xl={4}>
            <PricingCard
              title={pricingTiers.opensource.title}
              sub={pricingTiers.opensource.desc}
              subsub={pricingTiers.opensource.promise}
              cta="View Source"
              ctaHref={staticData.repo}
              features={[
                "Dozens of built-in integrations",
                "Easily add your own integrations",
                "Email and GitHub-based support",
              ]}
              footer={
                <SafeExternalLink href={staticData.repo}>
                  Try it locally or deploy to your own infrastructure in minutes
                </SafeExternalLink>
              }
            />
          </Col>
          <Col md={12} lg={6} xl={4}>
            <PricingCard
              title={pricingTiers.cloud.title}
              sub={pricingTiers.cloud.desc}
              subsub={pricingTiers.cloud.promise}
              cta="Get Started"
              ctaHref="/get-started"
              features={[
                "Simple usage-based pricing",
                "Unlimited integrations",
                "Can write into your own database",
              ]}
              footer={
                <SafeExternalLink href={staticData.docs("/docs/cloud.html")}>
                  Learn more about WebhookDB Cloud
                </SafeExternalLink>
              }
            />
          </Col>
          <Col md={12} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 0 }}>
            <PricingCard
              title={pricingTiers.enterprise.title}
              sub={pricingTiers.enterprise.desc}
              subsub={pricingTiers.enterprise.promise}
              cta="Contact Us"
              ctaHref="/contact"
              features={[
                "$10,000 annual or $30,000 one-time",
                "Access to advanced integrations",
                "Dedicated professional services",
              ]}
              footer={
                <SafeExternalLink href={staticData.docs("/docs/enterprise.html")}>
                  See what integrations are available with our Enterprise license
                </SafeExternalLink>
              }
            />
          </Col>
        </Row>
      </div>
    </WavesLayout>
  );
}

function PricingCard({ title, cta, ctaHref, sub, subsub, features, footer }) {
  return (
    <div className="d-flex justify-content-center pt-5 h-100">
      <div className="max-width-sm p-5 mx-2 shadow-lg rounded bg-light d-flex flex-column h-100">
        <h3 className="text-center">{title}</h3>
        <p className="lead">{sub}</p>
        <p className="lead font-weight-bold mb-5">{subsub}</p>
        {features.map((feature, idx) => (
          <React.Fragment key={feature}>
            <div>
              <CheckmarkSvg />
              <span className="m-2">{feature}</span>
            </div>
            {idx !== features.length - 1 && <hr className="w-100" />}
          </React.Fragment>
        ))}
        <div className="h-100" />
        <Button
          size="lg"
          block
          className="mt-5"
          href={ctaHref}
          as={ctaHref.startsWith("https://") ? undefined : RLink}
        >
          {cta}
        </Button>
        <div className="mt-4">{footer}</div>
      </div>
    </div>
  );
}
